import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import classNames from "classnames";
import config from "../../../data/SiteConfig";

const navQuery = graphql`
  {
    projects: allMarkdownRemark(
      filter: { fields: { template: { eq: "projects" } } }
      sort: { fields: [frontmatter___year], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            year
          }
        }
      }
    }
    pages: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default class SiteNav extends React.Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: false,
    };
  }
  toggleNavbar() {
    console.log("toggleNavbar invoked");
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <StaticQuery
        query={navQuery}
        render={(data) => (
          <nav
            class="navbar is-fixed-top is-transparent"
            role="navigation"
            aria-label="main navigation"
          >
            <div class="container">
              <div class="navbar-brand">
                <Link className="navbar-item" to="/">
                  <strong>dark</strong>inventions
                </Link>

                <a
                  role="button"
                  className={classNames(
                    { "is-active": this.state.collapsed },
                    "navbar-burger",
                    "burger"
                  )}
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarBasicExample"
                  onClick={this.toggleNavbar}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>

              <div
                id="navbarBasicExample"
                className={classNames(
                  { "is-active": this.state.collapsed },
                  "navbar-menu"
                )}
              >
                <div class="navbar-start">
                  <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">About</a>

                    <div class="navbar-dropdown">
                      <Link className="navbar-item" to="/about/">
                        Biography
                      </Link>
                      <Link className="navbar-item" to="/about/people/">
                        People
                      </Link>
                      <Link className="navbar-item" to="/about/repertoire/">
                        Repertoire
                      </Link>
                    </div>
                  </div>
                  <div class="navbar-item has-dropdown is-hoverable">
                    <Link className="navbar-link" to="/projects">Projects</Link>
                    <div class="navbar-dropdown">
                      {data.projects.edges.map((item) => {
                        return (
                          <Link
                            className="navbar-item"
                            to={item.node.fields.slug}
                          >
                            {item.node.frontmatter.title}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  
                  <Link className="navbar-item" to="/discover">Discover</Link>

                  <Link className="navbar-item" to="/shop">
                    Shop
                  </Link>
                </div>

                <div class="navbar-end">
                  {config.socials.map((item) => (
                    <a class="navbar-item" href={item.url} color="primary">
                      <span class="icon">
                        <i class={item.icon}></i>
                      </span>
                    </a>
                  ))}

                  <a class="navbar-item" href="/contact">
                    <span class="icon">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        )}
      />
    );
  }
}
