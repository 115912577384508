import React, { Component } from "react";
import config from "../../../data/SiteConfig";
import { Footer, Container, Content, Button, Columns } from "react-bulma-components";


class SiteFooter extends Component {
  render() {
    return (
      <Footer>
        <Container>
          <Columns>
            <Columns.Column size={12} className="impressum">
              <Content>
                <p dangerouslySetInnerHTML={{ __html: config.address }} />
                <p dangerouslySetInnerHTML={{ __html: config.companyDetails }} />
              </Content>
            </Columns.Column>
          </Columns>

        </Container>
      </Footer>
    );
  }
}

export default SiteFooter;
