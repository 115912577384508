import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import "../scss/main.scss";
import SiteFooter from "../components/Site/SiteFooter";
import Navbar from "../components/Site/SiteNav";

import '@fortawesome/fontawesome-free/js/all.js';

export default class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Helmet>
          <meta name="description" content={config.strapline} />
        </Helmet>
        <Navbar />
        {children}
        <SiteFooter />
      </div>
    );
  }
}


