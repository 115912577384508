module.exports = {
  siteTitle: "Dark Inventions", // Site title.
  strapline:
    "We are an artist-led performer-composer collective, whose work encompasses curation, composition, performance and outreach.",
  address: `
    <strong>Dark Inventions Limited</strong><br />
    51 Cherry Paddocks<br />
    Lincoln<br />
    LN3 4GW
  `,
  companyDetails: `
    Dark Inventions Limited is a company limited by guarantee<br />
    Company no: 09974974
  `,
  stripe: {
    clientId: "pk_test_w2uNCdXaTne1KbACTmRivpvb00pGeVnDoD",
    successUrl: "http://darkinventions.co.uk/shop/success",
    cancelUrl: "http://darkinventions.co.uk/shop/cancelled",
  },
  socials: [
    {
      icon: "fab fa-facebook-square",
      url: "https://www.facebook.com/darkinventions"
    },
    {
      icon: "fab fa-twitter-square",
      url: "https://www.twitter.com/dark_inventions"
    }
  ]
};
